import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

function TryDoinkCards() {
  // Request message
  const [showRequestMessage, setShowRequestMessage] = useState(false);
  const [requestMessage, setRequestMessage] = useState();
  const [requestSuccess, setRequestSuccess] = useState();

  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
  });

  const requestDoinkCards = (doinkCardsTrialInfo) => {
    axios
      .post(
        "https://us-central1-doink-672a6.cloudfunctions.net/app/tryDoinkCards",
        doinkCardsTrialInfo
      )
      .then(() => {
        setShowRequestMessage(true);
        setRequestSuccess("success");
        setRequestMessage(
          "Successfully Requested Doink Cards. Check your email for more information."
        );
      })
      .catch(() => {
        setShowRequestMessage(true);
        setRequestSuccess("error");
        setRequestMessage(
          "Something went wrong when requesting Doink Cards. Please try again."
        );
      })
      .finally(() => {
        setTimeout(() => {
          setShowRequestMessage(false);
        }, 6000);
      });
  };

  return (
    <div className="try-doink-cards" id="TryDoinkCards">
      <div className="try-doink-cards-container">
        <div className="try-it-columns">
          <div className="try-it-column try-it-column-description">
            <h2>Try It Before You Buy It</h2>
            <h5>
              We are confident you and your tournament players will love Doink
              Cards which is why we want you to try out the game for free.
            </h5>
            <h5>
              Complete the form and we will send you an access code for Doink
              Cards.
            </h5>
          </div>
          <div className="try-it-column">
            <form
              className="try-it-form"
              id="doink-trial"
              onSubmit={handleSubmit(requestDoinkCards)}
            >
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "Name required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Name"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: "Email required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name="tournamentLink"
                control={control}
                defaultValue=""
                rules={{ required: "Tournament Link required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Tournament Link"
                    variant="outlined"
                    helperText={
                      error
                        ? error.message
                        : "To help us ensure you are a TD, please provide a link to the tournament you are hosting"
                    }
                    onChange={onChange}
                    error={!!error}
                    value={value}
                  />
                )}
              />
              {showRequestMessage && (
                <span className={`alert ${requestSuccess}`}>
                  {requestMessage}
                </span>
              )}
              <Button
                disabled={!formState.isValid}
                type="submit"
                variant="contained"
                color="primary"
              >
                Request Doink Cards
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TryDoinkCards;
