import React from "react";
import HostLobby from "../assets/images/hosting_lobby_mockup.png";
import PlayCards from "../assets/images/play_cards_mockup.png";
import ScoreTracking from "../assets/images/score_tracking_mockup.png";

function DeepDive() {
  return (
    <section className="doink-deep-dive">
      <div className="doink-deep-dive-container">
        <h2>Doink Card Features</h2>
        <div className="doink-features">
          <div className="doink-feature__row">
            <div className="doink-feature">
              <div className="doink-feature-card image-card">
                <div className="doink-feature-card__image">
                  <img src={PlayCards} alt="Play Cards" />
                </div>
                <div className="doink-feature-card__content">
                  <div className="doink-feature-card__header">
                    100+ Rule Cards & Multiple Rule Sets
                  </div>
                  <div className="doink-feature-card__body">
                    Doink Cards has a collection of over 90 unique rule cards
                    that you can play against your friends and multiple rule
                    sets which makes each game completely different than all of
                    the rest.
                  </div>
                </div>
              </div>
            </div>
            <div className="doink-feature">
              <div className="doink-feature-card image-card">
                <div className="doink-feature-card__image">
                  <img src={HostLobby} alt="Host Lobby" />
                </div>
                <div className="doink-feature-card__content">
                  <div className="doink-feature-card__header">
                    Host Games & Invite Your Friends
                  </div>
                  <div className="doink-feature-card__body">
                    In Doink Cards, you can customize each game to constantly
                    mix up gameplay. Once you have setup the perfect game, you
                    can easily add your friends to the game so everyone can
                    start playing!
                  </div>
                </div>
              </div>
            </div>
            <div className="doink-feature">
              <div className="doink-feature-card image-card">
                <div className="doink-feature-card__image">
                  <img src={ScoreTracking} alt="score tracking" />
                </div>
                <div className="doink-feature-card__content">
                  <div className="doink-feature-card__header">
                    Track Your Score In Game
                  </div>
                  <div className="doink-feature-card__body">
                    While your group is playing a round of Doink, you can easily
                    track your score within the game. This way you can know who
                    you need to target before you play your next rule card.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="doink-feature__row">
            <div className="doink-feature">
              <div className="doink-feature-card">
                <div className="doink-feature-card__header">
                  Get Started In Seconds
                </div>
                <div className="doink-feature-card__body">
                  Doink Cards is a web based game. Just send your friends a link
                  to your lobby and they can instantly join your game.
                </div>
              </div>
            </div>
            <div className="doink-feature">
              <div className="doink-feature-card">
                <div className="doink-feature-card__header">
                  Buy Once. Own Forever.
                </div>
                <div className="doink-feature-card__body">
                  Once you purchase Doink Cards you will be able to host
                  unlimited games and invite all of your friends to play with
                  you.
                </div>
                <div className="doink-feature-card__hint">
                  As long as one player own Doink Cards, everyone else plays for
                  free.
                </div>
              </div>
            </div>
            <div className="doink-feature">
              <div className="doink-feature-card">
                <div className="doink-feature-card__header">
                  Constantly Adding New Cards & Features
                </div>
                <div className="doink-feature-card__body">
                  Because Doink Cards is a digital game, we are constanlty
                  adding new features, rule cards, and other improvements for
                  free.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DeepDive;
