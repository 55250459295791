const getDoinkCardsBulkPrice = (quantity) => {
  if (quantity <= 0) {
    return [0, 0, ""];
  } else if (quantity < 25) {
    const cost = 5.5;
    const totalCost = cost * quantity;
    const discount = "45% OFF";
    return [cost, totalCost, discount];
  } else if (quantity < 50) {
    const cost = 5.0;
    const totalCost = cost * quantity;
    const discount = "50% OFF";
    return [cost, totalCost, discount];
  } else if (quantity < 75) {
    const cost = 4.75;
    const totalCost = cost * quantity;
    const discount = "52.5% OFF";
    return [cost, totalCost, discount];
  } else if (quantity < 100) {
    const cost = 4.25;
    const totalCost = cost * quantity;
    const discount = "57.5% OFF";
    return [cost, totalCost, discount];
  } else if (quantity < 125) {
    const cost = 3.75;
    const totalCost = cost * quantity;
    const discount = "62.5% OFF";
    return [cost, totalCost, discount];
  } else if (quantity < 150) {
    const cost = 3.25;
    const totalCost = cost * quantity;
    const discount = "67.5% OFF";
    return [cost, totalCost, discount];
  } else {
    const cost = 2.5;
    const totalCost = cost * quantity;
    const discount = "75% OFF";
    return [cost, totalCost, discount];
  }
};
export default getDoinkCardsBulkPrice;
