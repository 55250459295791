import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import DoinkIcon from "../assets/images/Doink_Icon.png";
import "./DoinkNavbar.css";

function DoinkNavbar() {
  return (
    <Navbar collapseOnSelect expand="md">
      <Navbar.Brand
        className="navbar-brand"
        onClick={() => {
          window.location.href = "https://doinkdiscgolf.com";
        }}
      >
        <img
          src={DoinkIcon}
          alt="Doink Icon"
          height="30"
          width="30"
          className="d-inline-block align-top"
        />{" "}
        Doink
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/">Doink Cards</Nav.Link>
          <Nav.Link href="/TournamentDirector">Tournament Directors</Nav.Link>
          <Nav.Link href="/#contact-us">Contact Us</Nav.Link>
          <Nav.Link className="faq" href="https://doinkdiscgolf.com/FAQ">
            FAQ
          </Nav.Link>
          <Nav.Link
            className="action-nav-link purchase-doink"
            href="https://app.doinkdiscgolf.com/shop"
          >
            Purchase
          </Nav.Link>
          <Nav.Link
            className="play-nav-link play-doink"
            href="https://app.doinkdiscgolf.com"
          >
            Play
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
export default DoinkNavbar;
