import React from "react";

function DoinkCardsInstructions() {
  return (
    <section className="doink-cards-video">
      <div className="doink-cards-video-container doink-cards-instructions">
        <div className="highlight-video-section">
          <div className="highlight-video-section-info">
            <span className="highlight-video-hint">New to Doink Cards?</span>
            <h1>Learn How To Play Doink Cards</h1>
            <p>This video will catch you up to speed!</p>
          </div>
        </div>
        <div className="highlight-video-section  highlight-video-section-video">
          <div className="video-wrapper">
            <iframe
              title="butt-slapp"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/FdStqhZo3J8"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DoinkCardsInstructions;
