import { createTheme } from "@material-ui/core/styles";
const MaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#08b3f8",
    },
  },
});

export default MaterialTheme;
