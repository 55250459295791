import React from "react";
import { Row, Jumbotron } from "react-bootstrap";
import "./TermsOfService.css";

function TermsOfService() {
  return (
    <Row className="terms-of-service-container legal flex">
      <Jumbotron>
        <h1>Terms of Service</h1>
        <div className="terms-of_service__section">
          <h2>Agreement between User and doinkdiscgolf.com</h2>
          <p>
            Welcome to doinkdiscgolf.com. The doinkdiscgolf.com website (the
            "Site") is comprised of various web pages operated by Southern
            Software LLC ("Southern Software"). The Site is a Technical
            Interview Preparation Site and aims to help prospective Software
            Engineers prepare for technical interviews. The Site is offered to
            you conditioned on your acceptance without modification of the
            terms, conditions, and notices contained herein (the "Terms").
            Please read these terms carefully, and keep a copy of them for your
            reference. All purchases are final and non-refundable.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Privacy</h2>
          <p>
            Your use of the Site is subject to Southern Software's Privacy
            Policy. Please review our Privacy Policy, which also governs the
            Site and informs users of our data collection practices.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Electronic Communications</h2>
          <p>
            Visiting the Site or sending emails to Southern Software constitutes
            electronic communications. You consent to receive electronic
            communications and you agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communications be in writing.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Your Account</h2>
          <p>
            For the purposes of these Terms and Conditions, unless otherwise
            noted, all references to “your account” mean / include your right to
            access and to use this site and your usage of this site. If you use
            this site, you are responsible for maintaining the confidentiality
            of your account and for restricting access to your computer, and you
            agree to accept responsibility for all activities that occur under
            your account. You may not assign or otherwise transfer your account
            to any other person or entity, and you may not share an account with
            another person. You acknowledge that Southern Software is not
            responsible for third party access to your account that results from
            theft or misappropriation of your account. Southern Software
            reserves the right to refuse or cancel service, terminate accounts,
            or remove or edit content.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Children under Thirteen</h2>
          <p>
            Southern Software does not knowingly collect, either online or
            offline, personal information from persons under the age of
            thirteen.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Links to Third Party Sites/Third Party Services</h2>
          <p>
            The Site may contain links to other websites ("Linked Sites"). The
            Linked Sites are not under the control of Southern Software and
            Southern Software is not responsible for the contents of any Linked
            Site, including without limitation any link contained in a Linked
            Site, or any changes or updates to a Linked Site. Southern Software
            is providing these links to you only as a convenience, and the
            inclusion of any link does not imply endorsement by Southern
            Software of the site or any association with its operators. Certain
            services may be made available via the Site and delivered by third
            party sites and organizations. By using any product, service or
            functionality originating from the doinkdiscgolf.com domain, you
            hereby acknowledge and consent that Southern Software may share such
            information and data with any third party with whom Southern
            Software has a contractual relationship to provide the requested
            product, service or functionality on behalf of the Site's users and
            customers.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>No Unlawful or Prohibited Use/Intellectual Property</h2>
          <p>
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use the Site strictly in accordance with these terms
            of use. As a condition of your use of the Site, you warrant to
            Southern Software that you will not use the Site for any purpose
            that is unlawful or prohibited by these Terms. You may not use the
            Site in any manner which could damage, disable, overburden, or
            impair the Site or interfere with any other party's use and
            enjoyment of the Site. You may not obtain or attempt to obtain any
            materials or information through any means not intentionally made
            available or provided for through the Site. All content included as
            part of the Service, such as text, graphics, logos, images, as well
            as the compilation thereof, and any software used on the Site, is
            the property of Southern Software or its suppliers and protected by
            copyright and other laws that protect intellectual property and
            proprietary rights. You agree to observe and abide by all copyright
            and other proprietary notices, legends or other restrictions
            contained in any such content and will not make any changes thereto.
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. Southern Software content is not for resale. Your use of
            the Site does not entitle you to make any unauthorized use of any
            protected content, and in particular you will not delete or alter
            any proprietary rights or attribution notices in any content. You
            will use protected content solely for your personal use, and will
            make no other use of the content without the express written
            permission of Southern Software and the copyright owner. You will
            not record, upload, or stream videos of yourself using any part of
            the content and/or of the platform that requires purchasing in order
            to be accessed. You agree that you do not acquire any ownership
            rights in any protected content. We do not grant you any licenses,
            express or implied, to the intellectual property of Southern
            Software or our licensors except as expressly authorized by these
            Terms.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Use of Communication Services</h2>
          <p>
            The Site may contain bulletin board services, chat areas, news
            groups, forums, communities, personal web pages, calendars, and/or
            other message or communication facilities designed to enable you to
            communicate with the public at large or with a group (collectively,
            "Communication Services"). You agree to use the Communication
            Services only to post, send and receive messages and material that
            are proper and related to the particular Communication Service. By
            way of example, and not as a limitation, you agree that when using a
            Communication Service, you will not: defame, abuse, harass, stalk,
            threaten or otherwise violate the legal rights (such as rights of
            privacy and publicity) of others; publish, post, upload, distribute
            or disseminate any inappropriate, profane, defamatory, infringing,
            obscene, indecent or unlawful topic, name, material or information;
            upload files that contain software or other material protected by
            intellectual property laws (or by rights of privacy of publicity)
            unless you own or control the rights thereto or have received all
            necessary consents; upload files that contain viruses, corrupted
            files, or any other similar software or programs that may damage the
            operation of another's computer; advertise or offer to sell or buy
            any goods or services for any business purpose, unless such
            Communication Service specifically allows such messages; conduct or
            forward surveys, contests, pyramid schemes or chain letters;
            download any file posted by another user of a Communication Service
            that you know, or reasonably should know, cannot be legally
            distributed in such manner; falsify or delete any author
            attributions, legal or other proper notices or proprietary
            designations or labels of the origin or source of software or other
            material contained in a file that is uploaded; restrict or inhibit
            any other user from using and enjoying the Communication Services;
            violate any code of conduct or other guidelines which may be
            applicable for any particular Communication Service; harvest or
            otherwise collect information about others, including e-mail
            addresses, without their consent; violate any applicable laws or
            regulations. Southern Software has no obligation to monitor the
            Communication Services. However, Southern Software reserves the
            right to review materials posted to a Communication Service and to
            remove any materials in its sole discretion. Southern Software
            reserves the right to terminate your access to any or all of the
            Communication Services at any time without notice for any reason
            whatsoever. Southern Software reserves the right at all times to
            disclose any information as necessary to satisfy any applicable law,
            regulation, legal process or governmental request, or to edit,
            refuse to post or to remove any information or materials, in whole
            or in part, in Southern Software's sole discretion. Always use
            caution when giving out any personally identifying information about
            yourself or your children in any Communication Service. Southern
            Software does not control or endorse the content, messages or
            information found in any Communication Service and, therefore,
            Southern Software specifically disclaims any liability with regard
            to the Communication Services and any actions resulting from your
            participation in any Communication Service. Managers and hosts are
            not authorized Southern Software spokespersons, and their views do
            not necessarily reflect those of Southern Software. Materials
            uploaded to a Communication Service may be subject to posted
            limitations on usage, reproduction and/or dissemination. You are
            responsible for adhering to such limitations if you upload the
            materials.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>
            Materials Provided to the Site or Posted on Any Southern Software
            Web Page
          </h2>
          <p>
            Southern Software does not claim ownership of the materials you
            provide to the Site (including feedback and suggestions) or post,
            upload, input or submit to the Southern Software Site (collectively
            "Submissions"). However, by posting, uploading, inputting, providing
            or submitting your Submission you are granting Southern Software
            permission to use your Submission in connection with the operation
            of its Internet businesses including, without limitation, the rights
            to: copy, distribute, transmit, publicly display, publicly perform,
            reproduce, edit, translate and reformat your Submission; and to
            publish your name in connection with your Submission. No
            compensation will be paid with respect to the use of your
            Submission, as provided herein. Southern Software is under no
            obligation to post or use any Submission you may provide and may
            remove any Submission at any time in Southern Software's sole
            discretion. By posting, uploading, inputting, providing or
            submitting your Submission you warrant and represent that you own or
            otherwise control all of the rights to your Submission as described
            in this section including, without limitation, all the rights
            necessary for you to provide, post, upload, input or submit the
            Submissions.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Affiliate Program</h2>
          <p>
            The Southern Software Affiliate program offers you the opportunity
            to become an Southern Software Affiliate. To request to become an
            Southern Software Affiliate, you must provide an email address (this
            email address, which should be associated with your PayPal account,
            is used solely to send you payouts), at which point Southern
            Software may or may not approve your request to become an Affiliate.
            If approved, Southern Software will give you a unique promo code
            that allows you to make a percentage of every Southern Software sale
            that uses your promo code. Southern Software reserves the right, in
            its sole discretion, to change the discount that your promo code
            provides as well as the percentage cut that you make from each sale
            and to terminate your affiliateship at any time and for any reason.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>International Users</h2>
          <p>
            The Service is controlled, operated and administered by Southern
            Software from our offices within the USA. If you access the Service
            from a location outside the USA, you are responsible for compliance
            with all local laws. You agree that you will not use the Southern
            Software Content accessed through the Site in any country or in any
            manner prohibited by any applicable laws, restrictions or
            regulations.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Indemnification</h2>
          <p>
            You agree to indemnify, defend and hold harmless Southern Software,
            its officers, directors, employees, agents and third parties, for
            any losses, costs, liabilities and expenses (including reasonable
            attorney's fees) relating to or arising out of your use of or
            inability to use the Site or services, any user postings made by
            you, your violation of any terms of this Agreement or your violation
            of any rights of a third party, or your violation of any applicable
            laws, rules or regulations. Southern Software reserves the right, at
            its own cost, to assume the exclusive defense and control of any
            matter otherwise subject to indemnification by you, in which event
            you will fully cooperate with Southern Software in asserting any
            available defenses.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Liability Disclaimer</h2>
          <p>
            SOUTHERN SOFTWARE LLC IS AN INDEPENDENT COMPANY. SOUTHERN SOFTWARE
            DOES NOT REPRESENT AND IS NOT AFFILIATED WITH ITS FOUNDERS’
            EMPLOYERS OR ANY OTHER COMPANY, ORGANIZATION OR ASSOCIATION IN ANY
            WAY WHATSOEVER. THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES
            INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES
            OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
            INFORMATION HEREIN. SOUTHERN SOFTWARE, LLC AND/OR ITS SUPPLIERS MAY
            MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME. SOUTHERN
            SOFTWARE LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
            SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
            THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
            CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
            WARRANTY OR CONDITION OF ANY KIND. SOUTHERN SOFTWARE LLC AND/OR ITS
            SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD
            TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
            GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
            IN NO EVENT SHALL SOUTHERN SOFTWARE LLC AND/OR ITS SUPPLIERS BE
            LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
            LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF
            OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE,
            WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
            PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION,
            SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH
            THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER
            BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
            EVEN IF SOUTHERN SOFTWARE LLC OR ANY OF ITS SUPPLIERS HAS BEEN
            ADVISED OF THE POSSIBILITY OF DAMAGES. IF YOU ARE DISSATISFIED WITH
            ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR
            SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Termination/Access Restriction</h2>
          <p>
            Southern Software reserves the right, in its sole discretion, to
            terminate your access to the Site and the related services or any
            portion thereof at any time, without notice.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Applicable Law/Jurisdiction and Venue</h2>
          <p>
            To the maximum extent permitted by law, this agreement is governed
            by the laws of the State of Georgia and you hereby consent to the
            exclusive jurisdiction and venue of courts in Georgia in all
            disputes arising out of or relating to the use of the Site. Use of
            the Site is unauthorized in any jurisdiction that does not give
            effect to all provisions of these Terms, including, without
            limitation, this section.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Other Provisions</h2>
          <p>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and Southern Software as a result of
            this agreement or use of the Site. Southern Software's performance
            of this agreement is subject to existing laws and legal process, and
            nothing contained in this agreement is in derogation of Southern
            Software's right to comply with governmental, court and law
            enforcement requests or requirements relating to your use of the
            Site or information provided to or gathered by Southern Software
            with respect to such use. If any part of this agreement is
            determined to be invalid or unenforceable pursuant to applicable law
            including, but not limited to, the warranty disclaimers and
            liability limitations set forth above, then the invalid or
            unenforceable provision will be deemed superseded by a valid,
            enforceable provision that most closely matches the intent of the
            original provision and the remainder of the agreement shall continue
            in effect. Unless otherwise specified herein, this agreement
            constitutes the entire agreement between the user and Southern
            Software with respect to the Site and it supersedes all prior or
            contemporaneous communications and proposals, whether electronic,
            oral or written, between the user and Southern Software with respect
            to the Site. A printed version of this agreement and of any notice
            given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this agreement
            to the same extent and subject to the same conditions as other
            business documents and records originally generated and maintained
            in printed form. It is the express wish to the parties that this
            agreement and all related documents be written in English.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Changes to Terms</h2>
          <p>
            Southern Software reserves the right, in its sole discretion, to
            change the Terms under which the Site is offered. The most current
            version of the Terms will supersede all previous versions. Southern
            Software encourages you to periodically review the Terms to stay
            informed of our updates.
          </p>
        </div>
        <div className="terms-of_service__section">
          <h2>Contact Us</h2>
          <p>
            Southern Software welcomes your questions or comments regarding the
            Terms. You may address your questions or comments to Southern
            Software LLC by email BrandonHancock@doinkdiscgolf.com.
          </p>
        </div>
      </Jumbotron>
    </Row>
  );
}

export default TermsOfService;
