import React from "react";
import "./Footer.css";

function Footer() {
	return (
		<div className="footer">
			<div className="footer-col">
				<p>A Southern Software Product</p>
			</div>
			<div className="footer-col">
				<p>Doink Disc Golf</p>
			</div>
			<div className="footer-col">
				<a href="/privacyPolicy">Privacy Policy</a>
				<a href="/termsOfService">Terms</a>
				<a href="/dataDeletion">Data Deletion</a>
			</div>
		</div>
	);
}

export default Footer;
