import React from "react";
import Button from "react-bootstrap/Button";
import PhoneMockup from "../assets/images/Doink_Disc_Golf_Product_Min.png";

function ProductHighlight() {
  return (
    <section className="product-highlight">
      <div className="product-highlight-shadow">
        <div className="product-highlight-container">
          <div className="highlight-information product-highlight-section">
            <h1>Doink Cards</h1>
            <p>Doink Cards is a new digital Disc Golf card game.</p>
            <p>
              Make each round of Disc Golf fun and competitive for friend groups
              of all skill levels.
            </p>
            <div className="highlight-actions">
              <Button
                className="purchase-doink"
                variant="primary"
                onClick={() => {
                  window.location.href = "https://app.doinkdiscgolf.com/shop";
                }}
              >
                Purchase
              </Button>
              <Button
                className="play-doink"
                variant="outline-primary"
                onClick={() => {
                  window.location.href = "https://app.doinkdiscgolf.com/";
                }}
              >
                Play
              </Button>
            </div>
          </div>
          <div className="product-highlight-section highlight-image">
            <div className="product-mockup">
              <img src={PhoneMockup} alt="Phone Mockup" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductHighlight;
