import React from "react";
import Cal from "../assets/images/Cal_B.jpg";
import Eric from "../assets/images/Eric_F.jpg";
import Kevin from "../assets/images/Kevin_L.jpg";

function Reviews() {
  return (
    <section className="doink-reviews-section">
      <div className="doink-reviews-section-container">
        <h2>Hundreds Of People Have Already Played Doink</h2>
        <div className="doink-reviews">
          <div className="doink-review">
            <div className="doink-review-card ml-auto">
              <div className="doink-review-card__header">
                <img src={Eric} alt="Eric Review" />
              </div>
              <div className="doink-review-card__body">
                <span>
                  <span className="strong">"</span>
                  Awesome app for players of all skill levels! Adds a extra
                  challenge for everyone!
                  <span className="strong">"</span>
                  <span className="strong"> -Eric F.</span>
                </span>
              </div>
            </div>
          </div>
          <div className="doink-review">
            <div className="doink-review-card">
              <div className="doink-review-card__header">
                <img src={Cal} alt="Cal Review" />
              </div>
              <div className="doink-review-card__body">
                <span>
                  <span className="strong">"</span>It's fun as hell whenever you
                  want a casual round.
                  <span className="strong">"</span>
                  <span className="strong"> -Cal B.</span>
                </span>
              </div>
            </div>
          </div>
          <div className="doink-review">
            <div className="doink-review-card ml-auto">
              <div className="doink-review-card__header">
                <img src={Kevin} alt="Kevin Review" />
              </div>
              <div className="doink-review-card__body">
                <span>
                  <span className="strong">"</span>I've had Ript in my bag for
                  years, now with Doink it's so much easier without the hassle
                  of actual cards.
                  <span className="strong">"</span>
                  <span className="strong"> -Kevin L.</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Reviews;
