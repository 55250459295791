import React from "react";
import { Row, Jumbotron } from "react-bootstrap";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
	return (
		<Row className="privacy-policy-container legal flex">
			<Jumbotron>
				<h1>Privacy Policy</h1>
				<div className="privacy-policy__section">
					<h2>Introduction</h2>
					<p>
						Privacy Policy for Southern Software LLC At Doink Disc Golf,
						accessible from doinkdiscgolf.com, one of our main priorities is the
						privacy of our visitors. This Privacy Policy document contains types
						of information that is collected and recorded by Doink Disc Golf and
						how we use it. If you have additional questions or require more
						information about our Privacy Policy, do not hesitate to contact us.
						This Privacy Policy applies only to our online activities and is
						valid for visitors to our website with regards to the information
						that they shared and/or collect in Doink Disc Golf. This policy is
						not applicable to any information collected offline or via channels
						other than this website. Our Privacy Policy was created with the
						help of the Privacy Policy Generator and the Free Privacy Policy
						Generator. Consent By using our website, you hereby consent to our
						Privacy Policy and agree to its terms.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Collection of your Personal Information</h2>
					<p>
						If you use Doink Disc Golf's products and services, we collect
						billing and credit card information. This information is used to
						complete the purchase transaction. Southern Software LLC encourages
						you to review the privacy statements of websites you choose to link
						to from Doink Disc Golf so that you can understand how those
						websites collect, use and share your information. Southern Software
						LLC is not responsible for the privacy statements or other content
						on websites outside of the Doink Disc Golf website.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Use of Personal Information</h2>
					<p>
						Souther Software LLC collects and uses your personal information to
						operate its website(s) and deliver the services you have requested.
						Southern Software LLC may also use your personally identifiable
						information to inform you of other products or services available
						from Doink Disc Golf. Southern Software LLC may also contact you via
						surveys to conduct research about your opinion of current services
						or of potential new services that may be offered. Southern Software
						LLC does not sell, rent or lease its customer lists to third
						parties. Southern Software LLC may share data with trusted parties
						to help perform statistical analysis, send you email or postal mail,
						provide customer support, or arrange for deliveries. All such third
						parties are prohibited from using your personal information except
						to provide these services to Southern Software LLC, and they are
						required to maintain the confidentiality of your information.
						Southern Software LLC will disclose your personal information,
						without notice, only if required to do so by law or in the good
						faith belief that such action is necessary to: (a) conform to the
						edicts of the law or comply with legal process served on Southern
						Software LLC; (b) protect and defend the rights or property of
						Southern Software LLC; and, (c) act under exigent circumstances to
						protect the personal safety of users of Southern Software LLC, or
						the public.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Automatically Collected Information</h2>
					<p>
						Information about your computer hardware and software may be
						automatically collected by Southern Software LLC. This information
						can include: your IP address, browser type, domain names, access
						times and referring website addresses. This information is used for
						the operation of the service, to maintain quality of the service,
						and to provide general statistics regarding use of the Doink Disc
						Golf website.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>What are Cookies?</h2>
					<p>
						A cookie is a small text file that is placed on your hard disk by a
						web page server to uniquely identify your browser or to store
						information in your browser. Cookies cannot be used to run programs
						or deliver viruses to your computer. A “web beacon” links web pages
						to servers and may be used to transmit information collected through
						cookies back to a web server. Most cookies expire after a certain
						period of time depending on what they are used for.
					</p>
					<p>
						Southern Software LLC uses both first-party cookies (which are set
						by our website when it is being visited) and third-party cookies
						(which are set by a server located outside the domain of our
						website). If you would like to learn more about this practice and to
						know your choices about not having this information collected and
						shared with third parties, visit http://networkadvertising.org/ or
						review our "Managing your Cookies" section further below.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Types of Cookies we use</h2>
					<h5>Essential Cookies</h5>
					<p>
						Certain cookies are necessary in order for Doink Disc Golf 's
						website to operate properly. For example, we use cookies to
						authenticate you. When you log on to our platform, authentication
						cookies are set which let us know who you are during a browsing
						session. These cookies also facilitate the social media login
						functionality (e.g., via Facebook, Google, etc) on our website.
						Essential cookies do not include advertising cookies, which are
						discussed further below.
					</p>
					<h5>Personalization/Customization Cookies</h5>
					<p>
						In some cases, we use cookies to remember the choices you make while
						browsing the Doink Disc Golf website and to store your preferences
						for you (e.g., if you edit the order of questions on the questions
						page). When you return to the same Doink Disc Golf website, the
						information you previously provided can be retrieved, so you can
						easily use the Southern Software LLC features that you customized.
						This functionality improves user experience and allows us to
						customize your site experience accordingly.
					</p>
					<h5>Analytics Cookies</h5>
					<p>
						Through our service providers, we may use analytics to collect
						information about your use of the Doink Disc Golf website to create
						reports and statistics on the performance of the website. Analytics
						collect information such as your IP address, type of device,
						operating system, geolocation, referring URLs, time and date of page
						visits, and which pages you visit. The information allows us to
						quantify our audience size, see the overall patterns of usage on the
						platform, help us record any difficulties you have with the website,
						and show us whether our advertising is effective or not.
					</p>
					<h5>Advertising Cookies</h5>
					<p>
						We may use advertising cookies to market Southern Software LLC
						products or services to you on third-party websites. For example, if
						you visit the Doink Disc Golf website, you may then see an
						advertisement for Southern Software LLC products or services on
						certain third-party websites you visit in the future. We sometimes
						use cookies delivered by third parties to track the performance of
						our advertisements. For example, these cookies remember which
						browsers have visited our websites. The information provided to
						third parties does not include personal information. This process
						helps us manage and track the effectiveness of our marketing
						efforts.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Managing your Cookies</h2>
					<p>
						You have the ability to accept or decline cookies. Most desktop web
						browsers automatically accept cookies, but you can usually modify
						your browser settings to decline cookies if you prefer. You'll want
						to refer to your browser's help section to do this since the
						instructions are usually browser-specific. Mobile devices typically
						allow you to control cookies through their system settings. Refer to
						your device manufacturer’s instructions for more information on how
						to do this. If you choose to decline cookies, you may not be able to
						fully experience the interactive features of the Southern Software
						LLC services.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Data Deletion</h2>
					<p>
						You have the ability to request for Southern Software LLC to delete
						all your user information. To learn more about our data deletion
						process, please <a href="/dataDeletion">click here.</a>
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Security of your Personal Information</h2>
					<p>
						Southern Software LLC secures your personal information from
						unauthorized access, use, or disclosure. Southern Software LLC uses
						the SSL Protocol for this purpose: When personal information (such
						as a credit card number) is transmitted to other websites, it is
						protected through the use of encryption - the Secure Sockets Layer
						(SSL) protocol.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Children under Thirteen</h2>
					<p>
						Southern Software LLC does not knowingly collect personally
						identifiable information from children under the age of thirteen.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Changes to this Statement</h2>
					<p>
						Southern Software LLC will occasionally update this Statement of
						Privacy to reflect company and customer feedback. Southern Software
						LLC encourages you to periodically review this Statement to be
						informed of how Southern Software LLC is protecting your
						information.
					</p>
				</div>
				<div className="privacy-policy__section">
					<h2>Contact Information</h2>
					<p>
						Southern Software LLC welcomes your questions or comments regarding
						this Statement of Privacy. If you believe that Southern Software LLC
						has not adhered to this Statement, please contact Southern Software
						LLC’s team by sending a message BrandonHancock@doinkdiscgolf.com.
					</p>
				</div>
			</Jumbotron>
		</Row>
	);
}

export default PrivacyPolicy;
