import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function DoinkCardGameModes() {
  return (
    <section className="doink-card-game-modes">
      <div className="doink-card-game-modes-container">
        <h2>There Is A Doink Card Game Mode For Every Group</h2>
        <div className="game-modes">
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
          >
            <div className="game-mode">
              <div className="game-mode-title">Survivor 🌴🔥🌊</div>
              <div className="game-mode-description">
                The host picks how many cards each player gets for this entire
                round. Use your cards wisely because you won't be able to draw
                any more! (Max of 15 cards)
              </div>
            </div>
            <div className="game-mode">
              <div className="game-mode-title">Mystery Box 🔮🎁</div>
              <div className="game-mode-description">
                Each player starts with 4 mystery cards. The rules of each card
                are hidden until you play the card. When you play a card, there
                a chance that a card will help you and there is a chance that
                the card will backfire on you.
              </div>
            </div>
            <div className="game-mode">
              <div className="game-mode-title">Helping Hand ✋🤞🖐</div>
              <div className="game-mode-description">
                Each player starts with 2 cards. The loser(s) of each hole are
                allowed to draw one more card to help them out during their
                struggle.
              </div>
            </div>
            <div className="game-mode">
              <div className="game-mode-title">Infinite Twos ♾️🃏</div>
              <div className="game-mode-description">
                Each player starts off with 2 cards. Every time you play a card,
                another card will take its place so you will never run out of
                cards!
              </div>
            </div>
            <div className="game-mode">
              <div className="game-mode-title">Brawl 🤼‍♂️🤜🤛</div>
              <div className="game-mode-description">
                Each player receives 3 card that they can use against their
                opponents, 2 cards they can use to help themselves, and 1
                mystery card. Use your cards wisely because you won't be able to
                draw any more!
              </div>
            </div>
            <div className="game-mode">
              <div className="game-mode-title">Top 5 🔝✋🗑️✌️</div>
              <div className="game-mode-description">
                Each player will receive 7 cards. Before the round starts, all
                players must get rid of their 2 least favorite cards for a total
                of 5 cards.
              </div>
            </div>
            <div className="game-mode">
              <div className="game-mode-title">Bottoms Up 🍑🔺</div>
              <div className="game-mode-description">
                Each player starts with 1 card. After each hole, all players get
                to draw a card except the player(s) with the lowest overall
                score. After all the new cards are drawn, the player(s) with the
                lowest overall score pick one player to discard a card.
              </div>
            </div>
          </Carousel>
        </div>
        <h3>And We Are Constantly Adding More For Free</h3>
      </div>
    </section>
  );
}

export default DoinkCardGameModes;
