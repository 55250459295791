import React from "react";
import { Row, Jumbotron } from "react-bootstrap";
import "./DataDeletion.css";

function DataDeletion() {
  return (
    <Row className="data-deletion-container legal flex">
      <Jumbotron>
        <h1>Data Deletion</h1>
        <div className="data-deletion__section">
          <h2>Data Deletion Instructions:</h2>
          <p>
            If you would like to delete your Doink Disc Golf account and all of
            your user data, please email BrandonHancock@doinkdiscgolf.com.
          </p>
          <p>
            Within 24 hours, you will receive a confirmation email letting you
            know that all of your information has permenantly deleted.
          </p>
          <p>
            CAUTION: Deleting your account and your user-collected information
            cannot be undone.
          </p>
        </div>
      </Jumbotron>
    </Row>
  );
}

export default DataDeletion;
