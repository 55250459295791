import React from "react";
import "./FAQ.css";
import Faq from "react-faq-component";

function FAQ() {
  const data = {
    title: "Doink Disc Golf FAQ",
    rows: [
      {
        title: "Do my friends have to pay to play with me?",
        content:
          "No. Once you own Doink Cards, you can host as many Doink Card games as you'd like and all of your friends can play with you for free!",
      },
      {
        title:
          "Is there an app in the App Store or Play Store for Doink Cards?",
        content: `
          <p>Unfortunately, no. We plan on keeping Doink as a web-based game because it allows us to add new features at a faster pace and provide more value to you!</p>
          <p>However, if you would like Doink to look and feel like a native app on your phone, we have instructions on the login page of our app (<a href="https://app.doinkdiscgolf.com">app.doinkdiscgolf.com</a>). These instructions will show you how to add Doink to your phone's home screen. Once it is on your phone's home screen, the game will play just like a regular phone app!</p>
          <p>If you have any questions about this process or need any help, please email us!</p>
          `,
      },
      {
        title:
          "Is Doink Cards a subscription and do I have to pay for updates?",
        content: `
          <p>Once you purchase Doink Cards, you own the game forever. There is no subscription. Purchase once, own forever. Everything is simpler that way!</p>
          <p>Also, you will never have to pay for an update on Doink Cards. We are constantly adding new cards, Doink Card modes, and new features and you will get all of these for free. Why? Because you are awesome and you deserve to play the best Disc Golf game out there!</p>
          `,
      },
      {
        title:
          "What makes Doink Cards different from other Disc Golf card games?",
        content: `
          <p>There are already a few Disc Golf card games on the market and they are all great games. Here are a few key features that  separate Doink Cards from the rest.</p>
          <ul>
            <li>Doink Cards has more unique cards than any other card game on the market which will ensure that you and your friends feel like you are playing a completely new game each time you play Doink Cards.</li>
            <li>Doink Cards has multiple modes that you can play which completely change up the game. Want absolute chaos and a ton of fun? Play Infinite 2's and never run out of cards to play! This is a great way to equalize skill gaps between players. Want a fun and strategy at the same time? Play Survivor where you will start off with a few cards at the beginning of the round and have to strategically play these few cards throughout the whole round!</li>
            <li>Doink Cards is community-driven where we allow you to submit your own cards so they can be played by everyone! All of our cards that have a green icon in the top right corner are rule cards created by our awesome and creative players! We also are always striving to improve Doink Cards which is why we are constantly incorporating features suggested by our players. We are super excited for you to become apart of our growing community!</li>
            <li>Everything is digital which means that you don't have to worry about keeping up with physical cards or other Disc Golf game items. Also, you don't even have to wait for shipping which is so early 2000's! </li>
          </ul>
          `,
      },
      {
        title: "What do I get when I purchase Doink Cards?",
        content: `
          <ul>
            <li>100+ Unique Rule Cards</li>
            <li>Constantly adding new cards and card modes</li>
            <li>Track your score as you play</li>
            <li>Purchase once and own the Doink Cards forever (No subscription)</li>
            <li>Host unlimited games for your friends so they can play for free</li>
          </ul>
          `,
      },
      {
        title: "Error: This browser is not supported or 3rd party cookies and data may be disabled.?",
        content: `
        <p>You might receive this issue if you try logging into Doink when you are in an Incognito browser. For best results, try logging into Doink using Chrome or Safari while not in Incognito Mode.</p>
          `,
      },
    ],
  };
  const styles = {
    rowTitleTextSize: "20px",
    rowTitleColor: "#08b3f8",
    rowContentColor: "#333",
  };
  const config = {
    animate: true,
    tabFocus: true,
  };

  return (
    <div className="faq">
      <div className="faq-container">
        <Faq data={data} styles={styles} config={config} />
      </div>
    </div>
  );
}

export default FAQ;
