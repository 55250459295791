import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import { Card } from "react-bootstrap";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import axios from "axios";
import getDoinkCardsBulkPrice from "../utils/priceEstimator";

function TDOrderForm() {
  // Order Form
  const [emailAccessCodes, setEmailAccessCodes] = React.useState("email");

  // Bulk Order Message
  const [showBulkOrderMessage, setShowBulkOrderMessage] = useState(false);
  const [bulkOrderMessage, setBulkOrderMessage] = useState();
  const [bulkOrderSuccess, setBulkOrderSuccess] = useState();

  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
  });

  const bulkOrderDoinkCards = (bulkOrderInfo) => {
    bulkOrderInfo = {
      ...bulkOrderInfo,
      estimatedCost: getDoinkCardsBulkPrice(bulkOrderInfo.numberOfPlayers)[1],
    };

    axios
      .post(
        "https://us-central1-doink-672a6.cloudfunctions.net/app/bulkOrderDoinkCards",
        bulkOrderInfo
      )
      .then(() => {
        setShowBulkOrderMessage(true);
        setBulkOrderSuccess("success");
        setBulkOrderMessage(
          "Successfully requested Doink Cards. You will receive an email with more information shortly."
        );
      })
      .catch(() => {
        setShowBulkOrderMessage(true);
        setBulkOrderSuccess("error");
        setBulkOrderMessage(
          "Something went wrong when ordering Doink Cards. Please try again."
        );
      })
      .finally(() => {
        setTimeout(() => {
          setShowBulkOrderMessage(false);
        }, 6000);
      });
  };

  return (
    <div className="td-order" id="Order">
      <Card className="td-order-card">
        <h2>Order Doink Cards For Your Player Pack</h2>
        <p>1. Enter your email and name so we can contact you.</p>
        <p>2. Let us know how many Access Codes you need.</p>
        <p>
          3. Let us know if you would prefer us to email you the access codes or
          if you would prefer us to print them, cut them, and mail them to you.
        </p>
        <p className="td-order-card-note">
          Note: If you select email, we can send you the access codes within 24
          hours. If you select mail, it can take up to a week to ship them to
          you.
        </p>
        <form
          className="td-order-form"
          onSubmit={handleSubmit(bulkOrderDoinkCards)}
        >
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: "Name Required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Name"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{ required: "Email Required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
          <Controller
            name="numberOfPlayers"
            control={control}
            defaultValue=""
            rules={{ required: "Number of Players Required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Number of Players"
                variant="outlined"
                value={value}
                type="number"
                onChange={onChange}
                error={!!error}
                helperText={
                  error
                    ? error.message
                    : value
                    ? `Estimated Cost: $${getDoinkCardsBulkPrice(value)[1]}`
                    : null
                }
              />
            )}
          />
          <Controller
            name="deliveryMethod"
            control={control}
            defaultValue="email"
            rules={{ required: "Delivery Method Required" }}
            render={({ field: { onChange, value } }) => (
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Access Code Cards Delivery Method
                </FormLabel>
                <RadioGroup
                  aria-label="deliveryMethod"
                  name="deliveryMethod"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setEmailAccessCodes(e.target.value);
                  }}
                  color="primary"
                >
                  <FormControlLabel
                    value="email"
                    control={<Radio color="primary" />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="mail"
                    control={<Radio color="primary" />}
                    label="Mail"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          {emailAccessCodes === "mail" && (
            <>
              <Controller
                name="street"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Street"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="City"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name="state"
                control={control}
                defaultValue=""
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="State"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name="zip"
                control={control}
                defaultValue=""
                rules={{ required: "ZIP Code Required" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="ZIP Code"
                    variant="outlined"
                    value={value}
                    type="number"
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </>
          )}
          {showBulkOrderMessage && (
            <span className={`alert ${bulkOrderSuccess}`}>
              {bulkOrderMessage}
            </span>
          )}
          <Button
            disabled={!formState.isValid}
            variant="contained"
            color="primary"
            type="submit"
          >
            Order
          </Button>
        </form>
      </Card>
    </div>
  );
}

export default TDOrderForm;
