import DoinkNavbar from "./components/DoinkNavbar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";

// Pages
import Overview from "./pages/Overview";
import FAQ from "./pages/FAQ";
import TournamentDirector from "./pages/TournamentDirector";
import DataDeletion from "./pages/DataDeletion";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";

function App() {
	return (
		<div className="App">
			<Helmet>
				<title>Doink Disc Golf</title>
				<meta
					name="description"
					content="Doink Cards is a new digital Disc Golf card game. Make each round of Disc Golf fun and competitive for friend groups of all skill levels."
				/>
			</Helmet>
			<BrowserRouter forceRefresh={false}>
				<DoinkNavbar />
				<Switch className="app-container">
					<Route
						exact
						path="/TournamentDirector"
						component={TournamentDirector}
					/>
					<Route exact path="/FAQ" component={FAQ} />
					<Route exact path="/privacyPolicy" component={PrivacyPolicy} />
					<Route exact path="/termsOfService" component={TermsOfService} />
					<Route exact path="/dataDeletion" component={DataDeletion} />

					<Route path="/" component={Overview} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
