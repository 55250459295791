import React, { useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import ConvertKitForm from "convertkit-react";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

import "./Overview.css";
import DoinkCardGameModes from "../components/DoinkCardGameModes";
import ProductHighlight from "../components/ProductHighlight";
import DoinkCardsVideo from "../components/DoinkCardsVideo";
import DeepDive from "../components/DeepDive";
import DoinkCardsInAction from "../components/DoinkCardsInAction";
import Reviews from "../components/Reviews";
import DoinkCardsInstructions from "../components/DoinkCardsInstructions";
import BulkOrder from "../components/BulkOrder";
import Footer from "../components/Footer";

const CONVERT_KIT_CONFIG = {
	formId: 2113376,
	template: "mills",
	hideName: false,
	headingText: "Doink Disc Golf Newsletter",
	submitText: "Sign Up",
};

function Overview() {
	const [showSignUp, setShowSignUp] = useState(false);

	useState(() => {
		// Check to see if the user has ever signed up before
		const signup =
			localStorage.getItem("cksubscribed-a495758ce0") ||
			localStorage.getItem("cksubscribed-");
		if (!signup) {
			const signupDismiss = localStorage.getItem("signup-dismiss");
			if (signupDismiss) {
				// Only show the signup message ever 10 days.
				const lastDismissTime = dayjs(signupDismiss);
				const tenDaysAgo = dayjs().subtract(10, "d");
				if (!lastDismissTime || lastDismissTime.isBefore(tenDaysAgo)) {
					setTimeout(() => {
						setShowSignUp(true);
					}, 12000);
				}
			} else {
				setTimeout(() => {
					setShowSignUp(true);
				}, 12000);
			}
		}
	}, []);

	const handleToastClose = () => {
		setShowSignUp(false);
		localStorage.setItem("signup-dismiss", dayjs().toISOString());
	};

	const handleSignUp = () => {
		setShowSignUp(false);
	};

	return (
		<div className="overview-container">
			<Helmet>
				<title>Doink Cards</title>
				<meta
					name="description"
					content="In Doink Cards, everyone is given multiple rule cards. Play these rule cards to help yourself or to make things more challenging for your enemies."
				/>
			</Helmet>
			<ProductHighlight />
			<DoinkCardsVideo />
			<DeepDive />
			<DoinkCardsInAction />
			<DoinkCardGameModes />
			<DoinkCardsInstructions />
			<Reviews />
			<BulkOrder />
			<section className="recap">
				<div className="recap-container">
					<div className="recap-call-to-action recap-section">
						<h3>Ready to play Disc Golf at a new level?</h3>
						<p>
							Buy Doink Cards so you can host amazing Disc Golf games for you
							and your friends. Hundreds of players are already loving Doink
							Disc Golf and we are confident you will too.
						</p>
						<Button
							className="purchase-doink"
							onClick={() => {
								window.location.href = "https://app.doinkdiscgolf.com/shop";
							}}
							variant="primary"
						>
							Purchase
						</Button>
					</div>

					<div className="recap-contact recap-section" id="contact-us">
						<h3>Have a question?</h3>
						<p>Click the button below to checkout out our FAQ.</p>
						<Link to="FAQ">
							<Button className="faq-button" variant="outline-primary">
								FAQ
							</Button>
						</Link>
						<p>
							Feel free to email us. We do our best to always respond within 24
							hours.
						</p>
						<p>
							<span>Email:</span>{" "}
							<a href="mailto: BrandonHancock@doinkdiscgolf.com">
								BrandonHancock@doinkdiscgolf.com
							</a>
						</p>
					</div>
				</div>
			</section>
			<section className="signup-section">
				<ConvertKitForm {...CONVERT_KIT_CONFIG} />
			</section>
			<section className="footer-section">
				<Footer />
			</section>
			<Toast
				className="signup"
				onClose={() => handleToastClose()}
				show={showSignUp}
				animation={false}
			>
				<Toast.Header>
					<strong className="mr-auto">BECOME A DOINKER</strong>
				</Toast.Header>
				<Toast.Body>
					<p>Receive updates on special discounts, giveaways, and more!</p>
					<a
						className="btn btn-primary subscribe"
						data-formkit-toggle="a495758ce0"
						href="https://witty-builder-6023.ck.page/a495758ce0"
						onClick={() => handleSignUp()}
					>
						Sign Up
					</a>
				</Toast.Body>
			</Toast>
		</div>
	);
}

export default Overview;
