import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import "./TournamentDirector.css";
import PhoneMockup from "../assets/images/Doink_Disc_Golf_Product_Min.png";
import AccessCodeCard from "../assets/images/Doink_Cards_Coupon.png";
import RedeemScreenshot from "../assets/images/redeem_coupon.png";
import GameScreenshot from "../assets/images/play_cards_mockup_short.png";
import Sponsor from "../assets/images/game_sponsor.png";
import { Card } from "react-bootstrap";
import { Input } from "@material-ui/core";
import { Link } from "react-router-dom";
import TryDoinkCards from "../components/TryDoinkCards";
import TDOrderForm from "../components/TDOrderForm";
import AccessCardsSheet from "../assets/images/card_sheet_template.png";
import getDoinkCardsBulkPrice from "../utils/priceEstimator";
import { Helmet } from "react-helmet";

function TournamentDirector() {
  // Cost Estimate
  const [accessCodes, setAccessCodes] = useState(0);
  const [costPerPlayer, setCostPerPlayer] = useState(0);
  const [discount, setDiscount] = useState("");
  const [totalCost, setTotalCost] = useState(0);

  const getEstimate = (e) => {
    e.preventDefault();
    const quantity = e.target[0].value;
    setAccessCodes(quantity);
    const bulkPriceInfo = getDoinkCardsBulkPrice(quantity);
    setCostPerPlayer(bulkPriceInfo[0]);
    setTotalCost(bulkPriceInfo[1]);
    setDiscount(bulkPriceInfo[2]);
  };

  return (
    <div className="tournament-director-container">
      <Helmet>
        <title>Tournament Directors</title>
        <meta
          name="description"
          content="Disc Golf Tournament Directors, Upgrade Your Player Packs With Doink Cards!"
        />
      </Helmet>
      <section className="product-highlight">
        <div className="product-highlight-shadow">
          <div className="product-highlight-container">
            <div className="highlight-information product-highlight-section">
              <h1>
                Disc Golf Tournament Directors, Upgrade Your Player Packs With
                Doink Cards
              </h1>
              <p>Doink Cards Is A New Digital Disc Golf Card Game.</p>

              <div className="highlight-actions">
                <Button
                  className="purchase-doink"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.location.href =
                      "https://doinkdiscgolf.com/TournamentDirector#Order";
                  }}
                >
                  Order
                </Button>
                <Button
                  className="purchase-doink"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    window.location.href =
                      "https://doinkdiscgolf.com/TournamentDirector#TryDoinkCards";
                  }}
                >
                  Try For Free
                </Button>
              </div>
            </div>
            <div className="product-highlight-section highlight-image">
              <div className="product-mockup">
                <img src={PhoneMockup} alt="Phone Mockup" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="doink-cards-video">
        <div className="doink-cards-video-container doink-cards-ad ">
          <div className="highlight-video-section highlight-video-section-video tournament-highlight-video-section">
            <div className="video-wrapper">
              <iframe
                title="butt-slapp"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/tYVd1QG-9bk"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <a href="https://doinkdiscgolf.com/">
              <Button variant="contained" color="primary">
                Learn More
              </Button>
            </a>
          </div>
          <div className="highlight-video-section">
            <div className="highlight-video-section-info">
              <span className="highlight-video-hint">
                You might even get a few laughs along the way!
              </span>
              <h1>New to Doink Cards?</h1>
              <p>Don't worry! Watch this 60 second video to learn more.</p>
              <a href="https://doinkdiscgolf.com/">
                <Button variant="outlined" color="primary">
                  Learn More
                </Button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="explanation">
        <h2>Steps To Add Doink Cards To Your Tournament Player Packs</h2>
        <div className="explanation-columns">
          <Card className="explanation-card">
            <div className="explanation-card__header">
              1. Order access codes for player packs
            </div>
            <div className="explanation-card__content">
              Receive all the access code cards for your players on a PDF that
              you can print out.
            </div>
            <div className="explanation-card__image vertical">
              <img src={AccessCardsSheet} alt="Access Code Cards" />
            </div>
          </Card>
          <Card className="explanation-card">
            <div className="explanation-card__header">
              2. Place access codes in player packs
            </div>
            <div className="explanation-card__content">
              Each access card allows a player to own Doink Cards for free.
            </div>
            <div className="explanation-card__image horizontal">
              <img src={AccessCodeCard} alt="Access Code Card" />
            </div>
          </Card>
          <Card className="explanation-card">
            <div className="explanation-card__header">
              3. Players follow instructions on access cards
            </div>
            <div className="explanation-card__content">
              Players follow the instructions on the access code to redeem Doink
              Cards.
            </div>
            <div className="explanation-card__image horizontal">
              <img src={RedeemScreenshot} alt="Redeem Screenshot" />
            </div>
          </Card>
          <Card className="explanation-card">
            <div className="explanation-card__header">
              4. Players play Doink Cards with their friends
            </div>
            <div className="explanation-card__content">
              Players can now host games and play Doink Cards with all of their
              friends.
            </div>
            <div className="explanation-card__image horizontal">
              <img src={GameScreenshot} alt="Game Screenshot" />
            </div>
          </Card>
        </div>
      </section>
      <TryDoinkCards />
      <section className="benefits">
        <div className="benefits-container">
          <h2>What Makes Doink Cards Great For Player Pack?</h2>
          <div className="benefit-cards">
            <Card className="benefit-card">
              <div className="benefit-card__header">
                Upgrade Your Player Packs 🎁
              </div>
              <div className="benefit-card__content">
                <p>
                  Typical player packs include items (shirts, hats, insert other
                  generic swag here) that are soley focused on the player. Shake
                  things up with Doink Cards and give a gift that can be enjoyed
                  by the player and their friends.
                </p>
              </div>
            </Card>
            <Card className="benefit-card">
              <div className="benefit-card__header">
                Say Goodbye to 3 Week Lead Times 🙅
              </div>
              <div className="benefit-card__content">
                <p>
                  Ordering physical product can take over 3 weeks. If the
                  tournament funds don't drop in time, you will be the one
                  covering the costs. With Doink Cards, we will send you the
                  access codes within 24 hours of your purchase.
                </p>
              </div>
            </Card>
            <Card className="benefit-card">
              <div className="benefit-card__header">
                Don't Spend An 💪 And A 🦵
              </div>
              <div className="benefit-card__content">
                <p>
                  Most custom products will cost you an arm and a leg. With
                  Doink Cards, we offer bulk ordering discounts. The more you
                  buy, the more you save. This is our way of saying thank you
                  for supporting us!
                </p>
              </div>
            </Card>
          </div>
        </div>
      </section>
      <section className="customize" id="Customize">
        <div className="customize-rows">
          <div className="customize-header">
            <h2>Customize Doink Cards For Your Tournament</h2>
          </div>
          <div className="customize-content">
            <div className="customize-row first-customize-row">
              <div className="customize-column customize-description">
                <h3>Place Your Tournament Logo In Doink Cards</h3>
                <p>
                  Leave a long-lasting impression of your tournament by placing
                  your logo in Doink Cards.
                </p>
                <p>
                  Your tournament logo will appear in all Doink Card games
                  hosted by players that receive an access code from your
                  tournament.
                </p>
              </div>
              <div className="customize-column customize-image">
                <img src={Sponsor} alt="game sponsor" />
              </div>
            </div>
            <div className="customize-row last-customize-row">
              <div className="customize-column customize-image">
                <img src={AccessCodeCard} alt="Access Code Card" />
              </div>
              <div className="customize-column customize-description">
                <h3>Customize Access Codes For Your Players</h3>
                <p>
                  Change the XXXXX on the access card to be specific to your
                  tournament. Examples: PDGA2021, USDGC21, DGJuniors, etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cost-estimator" id="CostEstimator">
        <div className="cost-estimator-shadow">
          <h2>How Many Players Will Be In Your Upcoming Tournament?</h2>
          <div className="cost-estimator-columns">
            <div className="cost-estimator-column">
              <h3>How many access codes do you need?</h3>
              <form onSubmit={getEstimate} className="cost-estimator-form">
                <Input
                  type="number"
                  defaultValue={accessCodes}
                  fullWidth={false}
                />
                <Button type="submit" variant="outlined" color="primary">
                  Get Estimate
                </Button>
              </form>
            </div>
            <div className="cost-estimator-column">
              <div className="estimates">
                <div className="estimate">
                  <h3>Cost per Player</h3>
                  <span>
                    {costPerPlayer === 0 ? "???" : `$${costPerPlayer}`}
                  </span>
                </div>
                <div className="estimate">
                  <h3>Discount</h3>
                  <span>{discount === "" ? "???" : `${discount}`}</span>
                </div>
                <div className="estimate">
                  <h3>Total Cost</h3>
                  <span>{totalCost === 0 ? "???" : `$${totalCost}`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TDOrderForm />
      <section className="recap tournament-director-recap">
        <div className="recap-container">
          <div className="recap-call-to-action recap-section">
            <h3>Ready to add Doink Cards to your player packs?</h3>
            <p>
              Buy Doink Cards for your upcoming tournament player packs so your
              players can host amazing Disc Golf games for their friends.
              Hundreds of players are already loving Doink Disc Golf and we are
              confident they will too.
            </p>
            <Button
              className="purchase-doink"
              onClick={() => {
                window.location.href =
                  "https://doinkdiscgolf.com/TournamentDirector#Order";
              }}
              variant="contained"
              color="primary"
            >
              Purchase
            </Button>
          </div>

          <div className="recap-contact recap-section">
            <h3>Have a question?</h3>
            <p>Click the button below to checkout out our FAQ.</p>
            <Link to="FAQ">
              <Button className="faq-button" variant="outlined" color="primary">
                FAQ
              </Button>
            </Link>
            <p>
              Feel free to email us. We do our best to always respond within 24
              hours.
            </p>
            <p>
              <span>Email:</span>{" "}
              <a href="mailto: BrandonHancock@doinkdiscgolf.com">
                BrandonHancock@doinkdiscgolf.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TournamentDirector;
