import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import "./BulkOrder.css";

function BulkOrder() {
  return (
    <div className="bulk-order-container">
      <div className="bulk-order-shadow">
        <div className="bulk-order">
          <h2>Are You A Tournament Director?</h2>
          <h3>Upgrade your player packs with Doink Cards!</h3>
          <a href="https://doinkdiscgolf.com/TournamentDirector">
            <Button color="primary" variant="outlined">
              Learn more
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default BulkOrder;
