import React from "react";

function DoinkCardsInAction() {
  return (
    <section className="doink-in-action">
      <div id="doink-videos" className="doink-in-action-container">
        <h2>Watch Doink Cards In Action</h2>
        <div className="doink-in-action-videos">
          <div className="doink-in-action-video">
            <h3>Michael Mayo</h3>
            <div className="video-wrapper">
              <iframe
                title="mayo"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Y6TtnUTQWRw?start=57"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="doink-in-action-video">
            <h3>Swanky Disc Golf</h3>
            <div className="video-wrapper">
              <iframe
                title="swanky"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/ZXknmhCl16w?start=13"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="doink-in-action-video">
            <h3>Zach Biscardi</h3>
            <div className="video-wrapper">
              <iframe
                title="zach"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/GGEBvorlK0A?start=47"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DoinkCardsInAction;
